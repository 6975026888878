<template>
    <div class="refunds-list">
        <ui-table-skeleton v-if="loading" />
        <template v-else>
            <div class="refunds-list row g-2 mb-4">
                <div class="col-md-3">
                    <ui-input
                        id="search"
                        name="search"
                        label="Search refunds"
                        type="text"
                        placeholder="Enter keywords"
                        :debounce="500"
                        v-model:value="filters.q"
                    >
                        <template #prepend>
                            <span class="fas fa-search"></span>
                        </template>
                    </ui-input>
                </div>
                <template v-if="_available_filters">
                    <div class="col-md-auto">
                        <date-range-picker
                            ref="picker"
                            v-model="range"
                            opens="right"
                            time-picker
                            time-picker-24-hours
                            show-dropdowns
                            control-container-class="refunds-list__date-range-picker"
                            :linked-calendars="false"
                            @update="changeDate"
                        >
                            <template #input>
                                <em class="fa fa-fw fa-calendar ml-n1"></em>
                                {{ $dayjs(filters.range.startDate).format('D MMMM YYYY') }} -
                                {{ $dayjs(filters.range.endDate).format('D MMMM YYYY') }}
                                <strong class="caret"></strong>
                            </template>
                        </date-range-picker>
                    </div>
                    <div class="col-md-2">
                        <ui-select-multiple
                            id="status"
                            name="status"
                            class="w-100"
                            option-label="key"
                            option-value="value"
                            label="Status"
                            :options="_available_filters.status"
                            v-model:value="filters.status"
                        />
                    </div>
                    <div class="col-md-2">
                        <ui-select-multiple
                            id="gateway"
                            name="gateway"
                            class="w-100"
                            option-label="key"
                            option-value="value"
                            label="Gateway"
                            :options="_available_filters.gateways"
                            v-model:value="filters.destination"
                        />
                    </div> 
                    <div class="col-auto ms-auto">
                        <ui-button
                            icon="fas fa-download"
                            variant="primary"
                            :loading="downloadingReport"
                            :disabled="!CAN(['read_reports'])"
                            @click="downloadReport()"
                        >
                            Download
                        </ui-button>
                    </div>
                </template>
            </div>
            <ui-table class="mb-4" :items="refunds" :fields="fields" :busy="busy" :scopes="['read_refunds', 'write_refunds']">
                <template #cell(transaction_id)="data">
                    <div v-if="data.item.transaction_id">
                        <ui-link v-if="data.item.transaction" :to="`/refunds/info?id=${data.item.id}`" >
                            {{ data.item.transaction.transaction_id }}
                        </ui-link>
                    </div>
                    <span v-else class="badge bg-light text-dark">No information</span>
                </template>
                <template #cell(account)="data">
                    <span v-if="!data.item.account || Object.keys(data.item.account).length == 0" class="badge bg-light text-dark">
                        No information
                    </span>
                    <span v-else >
                        <ui-link weight="medium" :to="{ name: 'accounts-info', query: { id: data.item.account_id } }" target="_blank">
                            <span class="font-weight-600"> {{ data.item.account.first_name }} {{ data.item.account.last_name || '' }}</span>
                        </ui-link>
                        <div>
                            <ui-link underline weight="regular" :link="'mailto:' + data.item.account.email">
                                {{ data.item.account.email }}
                            </ui-link>
                        </div>
                    </span>
                </template>
                <template #cell(customer)="data">
                    <template v-if="data.item.transaction_id">
                        <ui-link v-if="data.item.transaction.customer.id" weight="medium" :to="{ name: 'accounts-info', query: { id: data.item.transaction.customer.id } }" target="_blank">
                            <span class="font-weight-600">{{ data.item.transaction.customer.first_name }} {{ data.item.transaction.customer.last_name || '' }}</span>
                        </ui-link>
                        <span v-else>
                            <span v-b-tooltip="'The account is not registered.'" class="fas fa-question-circle text-gray-500"></span>
                            {{ data.item.transaction.customer.first_name }} {{ data.item.transaction.customer.last_name || '' }}
                        </span>
                        <div>
                            <ui-link underline weight="regular" :link="'mailto:' + data.item.transaction.customer.email">
                                <span>{{ data.item.transaction.customer.email }}</span>
                            </ui-link>
                        </div>
                    </template>
                    <span v-else class="badge bg-light text-dark">No information</span>
                </template>
                <template #cell(destination)="data">
                    <span class="text-uppercase">{{ data.item.destination }}</span>
                </template>
                <template #cell(reason)="data">
                    <span class="text-capitalize" v-if="data.item.reason">{{ data.item.reason }}</span>
                    <span v-else class="badge bg-light text-dark">No information</span>
                </template>
                <template #cell(type)="data">
                    <span class="text-capitalize">{{ data.item.type }}</span>
                </template>
                <template #cell(status)="data">
                    <ui-status
                        class="d-inline-flex flex-column align-items-center text-capitalize fs-13px"
                        :status="data.item.status"
                    />
                </template>
                <template #cell(amount)="data">
                    {{ _currency(data.item.amount, data.item.currency, true) }}
                </template>
                <template #cell(created_at)="data">
                    {{ $dayjs(data.item.created_at).format('LL') }} <br />
                    {{ $dayjs(data.item.created_at).format('LTS') }}
                </template>
                <template #cell(updated_at)="data">
                    {{ $dayjs(data.item.updated_at).format('LL') }} <br />
                    {{ $dayjs(data.item.updated_at).format('LTS') }}
                </template>
                <template #cell(actions)="data">
                    <b-dropdown variant="none" no-caret toggle-class="border text-muted">
                        <template #button-content>
                            <span class="fas fa-ellipsis-v"></span>
                        </template>
                        <b-dropdown-item :link="`/refunds/info?id=${data.item.id}`">
                            See details
                        </b-dropdown-item>
                        <b-dropdown-item v-if="data.item.status == 'processing' && CAN(['write_refunds'])" @click="retryRefund(data.item.id)">
                            Retry refund
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
                <template #cellFull="data">
                    <tr v-if="data.item.error">
                        <td class="col-full" :colspan="fields.length">
                            <p class="text-center text-red p-3 mb-0">
                                <span class="fas fa-info-circle"></span>
                                {{ data.item.error }}
                            </p>
                        </td>
                    </tr>
                </template>
            </ui-table>
            <div class="d-flex justify-content-center">
                <ui-pagination v-model="filters.page" :pages="total_pages" class="mt-3" />
            </div>
        </template>
        <download-report-snackbar ref="downloadReportSnackbar" @downloadFinished="downloadingReport = false" />
    </div>
</template>

<script>
import DateRangePicker from 'daterange-picker-vue3';
import 'daterange-picker-vue3/dist/daterange-picker-vue3.css';

import DownloadReportSnackbar from '@/components/reports/DownloadReportSnackbar.vue';
import UiButton from '@/components/ui/buttons/Button';
import UiInput from '@/components/ui/Input';
import UiLink from '@/components/ui/Link.vue';
import UiPagination from '@/components/ui/Pagination';
import UiSelectMultiple from '@/components/ui/inputs/SelectMultiple';
import UiStatus from '@/components/ui/BadgeStatus';
import UiTable from '@/components/ui/Table.vue';
import UiTableSkeleton from '@/components/ui/skeleton/TableSkeleton.vue';

import table_mixin from '@/mixins/tables.mixin';

export default {
    components: {
        DateRangePicker,
        DownloadReportSnackbar,
        UiButton,
        UiInput,
        UiLink,
        UiPagination,
        UiSelectMultiple,
        UiStatus,
        UiTable,
        UiTableSkeleton,
    },
    mixins: [table_mixin],
    data() {
        const range = {
            startDate: this.$dayjs()
                .subtract(1, 'year')
                .startOf('day')
                .toDate(),
            endDate: this.$dayjs()
                .endOf('day')
                .toDate(),
        };

        return {
            busy: false,
            refunds: null,
            creating: false,
            downloadingReport: false,
            fields: [
                { key: 'transaction_id', label: 'Transaction ID' },
                { key: 'account', label: 'Account' },
                { key: 'customer', label: 'Customer' },
                { key: 'destination', label: 'Gateway' },
                { key: 'amount', label: 'Amount' },
                { key: 'created_at', label: 'Date' },
                { key: 'updated_at', label: 'Updated At' },
                { key: 'type', label: 'Type' },
                { key: 'reason', label: 'Reason' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: 'Actions' },
            ],
            filters: {
                q: '',
                page: 1,
                limit: 50,
                range: { ...range },
                account: null,
                status: null,
                destination: null,
            },
            inititialLoading: true,
            loading: false,
            range: { ...range },
            total_pages: null,
        };
    },
    computed: {
        downloadLink() {
            let report_url = '/_/reports/refunds';
            let search_params = new URLSearchParams();

            if (String(this.filters.q).trim() !== '') {
                search_params.append('q', this.filters.q);
            }

            if (this.filters.status && this.filters.status.length) {
                search_params.append('status', this.filters.status);
            }

            if (this.filters.reason && this.filters.reason.length) {
                search_params.append('reason', this.filters.reason);
            }
            
            if (this.filters.destination && this.filters.destination.length) {
                search_params.append('destination', this.filters.destination);
            }

            if (this.filters.range) {
                search_params.append('created_at[from]', this.$dayjs(this.filters.range.startDate).toISOString());
                search_params.append('created_at[to]', this.$dayjs(this.filters.range.endDate).toISOString());
            }

            return search_params ? `${report_url}?${search_params}` : report_url;
        },
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this._changeFilters(this.getRefunds);
            },
        },
        $route(to) {
            if (to.name === 'refunds') {
                this.filters = this._filtersByQuery(this.filters);
            }
        },
    },
    async mounted() {
        await this._getFilters('refunds');
    },
    methods: {
        changeDate(data) {
            this.filters.range = data;
        },
        downloadReport() {
            this.$refs.downloadReportSnackbar.download({name: 'Activity', url: this.downloadLink});
            this.downloadingReport = true;
        },
        async getRefunds() {
            try {
                if (this.inititialLoading) {
                    this.loading = true;
                }

                this.busy = true;

                const formattedFilters = JSON.parse(JSON.stringify(this.filters));
                const params = {
                    page: formattedFilters.page,
                    limit: formattedFilters.limit,
                    account: formattedFilters.account,
                    status: formattedFilters.status,
                    destination: formattedFilters.destination,
                    'created_at[from]': this.$dayjs(this.filters.range.startDate).toISOString(),
                    'created_at[to]': this.$dayjs(this.filters.range.endDate).toISOString(),
                };

                this._clearFilters(params);

                if (String(this.filters.q).trim() !== '') {
                    params.q = this.filters.q;
                }

                const { data } = await this.$axios.get('/_/refunds', { params });
                this.total_pages = data.pages;
                this.refunds = data.docs;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                if (this.inititialLoading) {
                    this.loading = false;
                    this.inititialLoading = false
                }

                this.busy = false;
            }
        },
        async retryRefund(refund_id) {
            try {
                await this.$axios.patch('/_/refunds/retry-id', {
                    ids: [refund_id],
                });
                this.$toast.success('Refund has been retried');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }
        }
    },
};
</script>

<style lang="scss">
.refunds-list {
    &__date-range-picker {
        align-items: center;
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        gap: 8px;
        height: 40px;
        padding: 4.5px 8px;
        width: 100%;

        .caret {
            margin-left: auto;

            &::before {
                color: $ecart-accent;
                content: '\f078';
                font-size: 16px;
                line-height: 1;
            }
        }
    }

    .vue-daterange-picker {
        width: 100%;
    }
}
</style>